export const RED = "#d32f2f";
export const GREEN = "#2e7d32";

export const MAP_CONTAINER_STYLE = {
  width: "100%",
  height: "435px",
  borderRadius: "4px",
  boxShadow:
    "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
  borderBottom: "1px solid #6f6f6f",
};

export const MAP_OPTIONS = {
  styles: [
    {
      featureType: "all",
      elementType: "all",
      stylers: [{ saturation: -100 }, { hue: "#000000" }, { lightness: 20 }],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: "#6f6f6f" }],
    },
  ],
};

export const EMPTY_FILTERS: Filters = {
  date: null,
  callsign: "",
  registration: "",
  departureAirport: "",
  arrivalAirport: "",
  flightID: "",
  tlc: "",
};

export const EMPTY_USER: User = {
  email: "",
  is_admin: false,
  operator_ref: 0,
  operator: null,
  id: "",
  password: null,
  tlc: null,
  pin: null
}

export const EMPTY_FLEET: Fleet = {
  name: "",
  id: "",
}

export const EMPTY_OPERATOR: Operator = {
  name: "",
  id: 0,
  company: "",
  contact_name: "",
  email: "",
  tel_no: "",
  icao_code: "",
  is_dashboard_enabled: false,
  settings: null,
}

export const LOCALE_EN = {
  format: "{reason} at line {line}",
  symbols: {
    colon: "colon", // 
    comma: "comma", // ,  ،  、
    semicolon: "semicolon", // ;
    slash: "slash", // /  relevant for comment syntax support
    backslash: "backslash", // \  relevant for escaping character
    brackets: {
      round: "round brackets", // ( )
      square: "square brackets", // [ ]
      curly: "curly brackets", // { }
      angle: "angle brackets", // < >
    },
    period: "period", // . Also known as full point, full stop, or dot
    quotes: {
      single: "single quote", // '
      double: "double quote", // "
      grave: "grave accent", // ` used on Javascript ES6 Syntax for String Templates
    },
    space: "space", //
    ampersand: "ampersand", // &
    asterisk: "asterisk", // *  relevant for some comment sytanx
    at: "at sign", // @  multiple uses in other coding languages including certain data types
    equals: "equals sign", // =
    hash: "hash", // #
    percent: "percent", // %
    plus: "plus", // +
    minus: "minus", // −
    dash: "dash", // −
    hyphen: "hyphen", // −
    tilde: "tilde", // ~
    underscore: "underscore", // _
    bar: "vertical bar", // |
  },
  types: {
    // ... Reference: https://en.wikipedia.org/wiki/List_of_data_structures
    key: "key",
    value: "value",
    number: "number",
    string: "string",
    primitive: "primitive",
    boolean: "boolean",
    character: "character",
    integer: "integer",
    array: "array",
    float: "float",
  },
  invalidToken: {
    tokenSequence: {
      prohibited: "'{firstToken}' token cannot be followed by '{secondToken}' token(s)",
      permitted: "'{firstToken}' token can only be followed by '{secondToken}' token(s)",
    },
    termSequence: {
      prohibited: "A {firstTerm} cannot be followed by a {secondTerm}",
      permitted: "A {firstTerm} can only be followed by a {secondTerm}",
    },
    double: "'{token}' token cannot be followed by another '{token}' token",
    useInstead: "'{badToken}' token is not accepted. Use '{goodToken}' instead",
    unexpected: "Unexpected '{token}' token found",
  },
  brace: {
    curly: {
      missingOpen: "Missing '{' open curly brace",
      missingClose: "Open '{' curly brace is missing closing '}' curly brace",
      cannotWrap: "'{token}' token cannot be wrapped in '{}' curly braces",
    },
    square: {
      missingOpen: "Missing '[' open square brace",
      missingClose: "Open '[' square brace is missing closing ']' square brace",
      cannotWrap: "'{token}' token cannot be wrapped in '[]' square braces",
    },
  },
  string: {
    missingOpen: "Missing/invalid opening string '{quote}' token",
    missingClose: "Missing/invalid closing string '{quote}' token",
    mustBeWrappedByQuotes: "Strings must be wrapped by quotes",
    nonAlphanumeric: "Non-alphanumeric token '{token}' is not allowed outside string notation",
    unexpectedKey: "Unexpected key found at string position",
  },
  key: {
    numberAndLetterMissingQuotes: "Key beginning with number and containing letters must be wrapped by quotes",
    spaceMissingQuotes: "Key containing space must be wrapped by quotes",
    unexpectedString: "Unexpected string found at key position",
  },
  noTrailingOrLeadingComma: "Trailing or leading commas in arrays and objects are not permitted",
};

export const COLORS_DARK = {
  default: "#D4D4D4",
  background: "#2A2E37",
  background_warning: "#1E1E1E",
  string: "#CE8453",
  number: "#B5CE9F",
  colon: "#49B8F7",
  keys: "#9CDCFE",
  keys_whiteSpace: "#AF74A5",
  primitive: "#6392C6",
};

export const PAGINATION_LIMIT = 7;
