import { FunctionComponent, useState } from "react";
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  Tooltip,
} from "@mui/material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { DeleteFlightModal } from "..";
import { formatDateTime } from "../../utils";

type Props = {
  items: Flight[];
};

export const FlightsList: FunctionComponent<Props> = ({ items }) => {
  if (!items?.length) return <Typography pt={3} pl={2}>No flights found</Typography>;

  const [deleteID, setDeleteId] = useState('');

  const handleOpenModal = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, id: string) => {
    e.preventDefault();
    setDeleteId(id);
  }

  const handleCloseModal = () => setDeleteId('');

  return (
    <>
      <DeleteFlightModal id={deleteID} onClose={handleCloseModal} />
      <List sx={{ paddingBottom: "26px", paddingTop: "12px" }} >
        {items.map((flight: any) => (
          <li key={`item-${flight.flightID}`}>
            <ListItem
              href={`/flight/${flight.flightID}`}
              alignItems="flex-start"
              component="a"
              sx={{ paddingBottom: "7px", paddingTop: "7px" }}
            >
              <ListItemText
                primary={<>Flight {flight.flightID}<Tooltip placement="top" title="Delete Flight">
                  <HighlightOffIcon sx={{
                    marginBottom: "4px",
                    verticalAlign: "bottom",
                    height: "16px"
                  }} onClick={(e) => handleOpenModal(e, flight.flightID)} />
                </Tooltip></>}
                secondary={
                  <>
                    Date:{" "}
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {formatDateTime(flight.scheduled_time_of_departure)}{" "}
                    </Typography>
                    Registration:{" "}
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {flight.aircraft_registration}{" "}
                    </Typography>
                    Departure:{" "}
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {flight.departure_airport}{" "}
                    </Typography>
                    Arrival:{" "}
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {flight.arrival_airport}{" "}
                    </Typography>
                    Identifier:{" "}
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {flight.flight_identifier}{" "}
                    </Typography>
                    TLC:{" "}
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {flight.user_tlc}
                    </Typography>
                  </>
                }
              />
            </ListItem>
            <Divider
              key={`divider-${flight.flightID}`}
              variant="middle"
              component="div"
            />
          </li>
        ))}
      </List>
    </>
  );
};
