import pinkMarker from "./assets/marker-pink.png";
import purpleMarker from "./assets/marker-purple.png";
import cyanMarker from "./assets/marker-cyan.png";
import { PAGINATION_LIMIT } from "./constants";

const dateFormatter = new Intl.DateTimeFormat("fr-CA", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
});

const timeFormatter = new Intl.DateTimeFormat("fr", {
  timeStyle: "short",
  timeZone: "UTC",
});

export const dateToHHMM = (utcDateString: string): string => {
  if (!utcDateString) return "-";

  const parts = utcDateString.split(' ');
  // Further split the date and time
  const dateParts = parts[0].split('-').map(part => parseInt(part, 10));
  const timeParts = parts[1].split(':').map(part => parseInt(part, 10));
  // Create the date object using Date.UTC
  const date = new Date(Date.UTC(dateParts[0], dateParts[1] - 1, dateParts[2], timeParts[0], timeParts[1], timeParts[2] || 0));

  let hours = date.getUTCHours();
  let minutes = date.getUTCMinutes();

  const hoursStr: string = hours < 10 ? '0' + hours : hours.toString();
  const minutesStr: string = minutes < 10 ? '0' + minutes : minutes.toString();

  return `${hoursStr}:${minutesStr}`;
}

export const subtractTime = (start: Date, end: Date) => {
  const minutesDiff = Math.abs(
    (new Date(end).getTime() - new Date(start).getTime()) / 60000
  );
  const hours = ~~(minutesDiff / 60);
  const minutes = ~~(minutesDiff % 60);
  return `${hours}h ${minutes}m`;
};

export const isDateValid = (date: Date) =>
  new Date(date).toString() !== "Invalid Date";

export const formatDate = (date: Date) => dateFormatter.format(date);

export const formatTime = (date: Date) =>
  date ? timeFormatter.format(new Date(date)) : "";

export const formatDateTime = (date: Date) => {
  const dateObj = new Date(date);

  return dateObj.toLocaleString("fr", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZone: "UTC",
    hour12: false,
  });
};

// Builds queries from params: ?flight_origin_data=2023-04-20&departure_airport=LFQQ&arrival_airport=LSZH...
export const buildFilterQueryParams = (filters: Filters, page: number) => {
  const { date, callsign, registration, departureAirport, arrivalAirport, flightID, tlc } = filters;

  let paramsString = `?limit=${PAGINATION_LIMIT}&offset=${(page - 1) * PAGINATION_LIMIT}`;

  if (date && isDateValid(date)) {
    paramsString += `&flight_origin_data=${formatDate(date)}`;
  }

  if (callsign) {
    paramsString += `&flight_identifier=${callsign}`;
  }

  if (registration) {
    paramsString += `&aircraft_registration=${registration}`;
  }

  if (departureAirport) {
    paramsString += `&departure_airport=${departureAirport}`;
  }

  if (arrivalAirport) {
    paramsString += `&arrival_airport=${arrivalAirport}`;
  }

  if (flightID) {
    paramsString += `&flightID=${flightID}`;
  }

  if (tlc) {
    paramsString += `&user_tlc=${tlc}`;
  }

  return paramsString;
};

export const calculatePath = (data: Waypoint[]) => {
  const path = [];

  for (let i = 0; i < data.length; i += 1) {
    path.push(data[i].coordinates);
  }

  return path;
};

export const calculateCenter = (data: Waypoint[]) => {
  if (!data.length) return;
  const coordsFirstArr = data[0].coordinates;
  const coordsLastArr = data[data.length - 1].coordinates;
  return {
    lat: (coordsFirstArr.lat + coordsLastArr.lat) / 2,
    lng: (coordsFirstArr.lng + coordsLastArr.lng) / 2,
  };
};

export const getMarker = (isAfqSet: boolean, isAtoSet: boolean) => {
  if (isAtoSet && isAfqSet) {
    return cyanMarker;
  }

  if (isAtoSet || isAfqSet) {
    return pinkMarker;
  }

  return purpleMarker;
};

export const getLabelColor = (isAfqSet: boolean, isAtoSet: boolean) => {
  if (isAtoSet && isAfqSet) {
    return "label-cyan";
  }

  if (isAtoSet || isAfqSet) {
    return "label-pink";
  }

  return "label-purple";
};

export const isAuthenticated = () =>
  localStorage.getItem("isAuthenticated") !== null;

export const validateEmail = (email: string) => {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return regex.test(email);
};

export const move = (source: any[], destination: any[], droppableSource: any, droppableDestination: any): { [key: string]: any; } => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result: { [key: string]: any; } = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

export const sortFiles = (files: string[]) => {
  // Add files to folders
  const pdfSummaryExp = /^\d+_[A-Z]+-\d{2}-\d{2}-\d{2} \d{2}:\d{2}-[A-Z]{4}-[A-Z]{4}\.pdf$/;
  const flightSummaryFiles = files?.filter((file: string) =>
    file.includes('_perfo_') || pdfSummaryExp.test(file)
  );
  const flightGeneratedPDFs = files?.filter((file: string) =>
    ['flightdata.', 'Flightdata.', 'flightlog.', 'Flightlog.', 'notes.', 'rvsm_data.', 'weathernotam.']
      .some(keyword => file.includes(keyword))
  );
  const ppsFiles = files?.filter((file: string) => file.includes('PPS'));
  const otherFiles = files?.filter((file: string) =>
    !flightSummaryFiles.includes(file) &&
    !flightGeneratedPDFs.includes(file) &&
    !ppsFiles.includes(file)
  );

  return { flightSummaryFiles, flightGeneratedPDFs, ppsFiles, otherFiles }
}
