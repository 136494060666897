import { FunctionComponent } from "react";
import { Container, Backdrop, CircularProgress, Box } from "@mui/material";
import { WhiteBackground, Page } from "../components";
import { useDashboard } from "../hooks/useDashboard";

const Dashboard: FunctionComponent = () => {
  const { data, isLoading, isSuccess } = useDashboard();
  const height = 726;

  return (
    <WhiteBackground>
      <Container>
        <Page>
          <Backdrop sx={{ zIndex: 1 }} open={isLoading}>
            <CircularProgress />
          </Backdrop>
          {!!isSuccess && data?.url && <Box sx={{ height: height - 61, overflow: "hidden" }}>
            <iframe
              src={`${data.url}#bordered=false&titled=false`}
              width="100%"
              height={height}
            />
          </Box>}
        </Page>
      </Container>
    </WhiteBackground>
  )
};

export default Dashboard;
