import { FunctionComponent } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Autocomplete, TextField } from "@mui/material";
import { Dayjs } from 'dayjs';

import { FiltersWrapper, ClearButton, DatePickerWrapper } from "..";

type Props = {
  options: { identifiers: string[], registrations: string[], departures: string[], arrivals: string[], ids: string[], tlcs: string[] };
  filters: Filters;
  onDateChange: (date: Dayjs | null) => void;
  onCallsignChange: (_: any, value: string | null) => void;
  onRegistrationChange: (_: any, value: string | null) => void;
  onDepartureAirportChange: (_: any, value: string | null) => void;
  onArrivalAirportChange: (_: any, value: string | null) => void;
  onFlightIDChange: (_: any, value: string | null) => void;
  onTLCChange: (_: any, value: string | null) => void;
};

export const Filters: FunctionComponent<Props> = (props) => {
  const {
    options: { identifiers = [], registrations = [], departures = [], arrivals = [], ids = [], tlcs = [] },
    filters: { date, callsign, registration, departureAirport, arrivalAirport, flightID, tlc },
    onDateChange,
    onCallsignChange,
    onRegistrationChange,
    onDepartureAirportChange,
    onArrivalAirportChange,
    onFlightIDChange,
    onTLCChange,
  } = props;

  const clearDate = () => {
    onDateChange(null);
  }
  // TODO: fix options warnings
  return (
    <FiltersWrapper>
      <DatePickerWrapper>
        <DatePicker
          value={date}
          onChange={onDateChange}
          format="YYYY-MM-DD"
          sx={{ width: "100%", paddingBottom: "15px" }}
          label="Date"
        />
        <ClearButton onClick={clearDate} />
      </DatePickerWrapper>
      <Autocomplete
        options={identifiers}
        value={callsign}
        onChange={onCallsignChange}
        renderInput={(params) => <TextField {...params} label="Callsign" />}
        sx={{ width: "100%", paddingBottom: "15px" }}
      />
      <Autocomplete
        options={registrations}
        value={registration}
        onChange={onRegistrationChange}
        renderInput={(params) => <TextField {...params} label="Registration" />}
        sx={{ width: "100%", paddingBottom: "15px" }}
      />
      <Autocomplete
        options={departures}
        value={departureAirport}
        onChange={onDepartureAirportChange}
        renderInput={(params) => (
          <TextField {...params} label="Departure airport" />
        )}
        sx={{ width: "100%", paddingBottom: "15px" }}
      />
      <Autocomplete
        options={arrivals}
        value={arrivalAirport}
        onChange={onArrivalAirportChange}
        renderInput={(params) => (
          <TextField {...params} label="Arrival airport" />
        )}
        sx={{ width: "100%", paddingBottom: "15px" }}
      />
      <Autocomplete
        options={ids.map((flightID: string) => flightID.toString())} // Convert number => string
        value={flightID}
        onChange={onFlightIDChange}
        renderInput={(params) => (
          <TextField {...params} label="Flight ID" />
        )}
        sx={{ width: "100%", paddingBottom: "15px" }}
      />
      <Autocomplete
        options={tlcs}
        value={tlc}
        onChange={onTLCChange}
        renderInput={(params) => (
          <TextField {...params} label="TLC" />
        )}
        sx={{ width: "100%", paddingBottom: "15px" }}
      />
    </FiltersWrapper>
  );
};
